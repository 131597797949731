<template>
  <div class="f-box">
    <div class="handle ">
      <div class="h-img"><img src="@/assets/img/que_02.png" alt=""></div>
      <div class="user">
        <span style="margin-right:0;">确认台编号：</span>
        <span style="margin-right:25px;">{{ taihao }}</span>
        <span style="margin-right:0;">确认责任人：</span>
        <span>{{ username }}</span
        ><el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
             <el-dropdown-item @click.native="showQueList">确认列表</el-dropdown-item>
            <el-dropdown-item @click.native="back">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="h-btn" @click="showQueList">确认列表</div> -->
      </div>
    </div>
    <!-- <div class="no-table" v-if="tableData.length == 0 && !showQList">
      <img src="@/assets/img/hehe_03.png" alt="" />
    </div> -->
    <div class="table-box scroll_content" v-if="!showQList">
      <div class="s-btn ">
        <el-input
          v-model="input"
          placeholder="请输入抽签顺序号"
          style="width: 350px"
          @keyup.enter.native="getSure"
        >
          <el-button slot="append" icon="el-icon" @click="getSure"
          style="font-size: 20px;"
            >搜索</el-button
          >
          <template slot="prepend"
            ><span style="font-size: 24px; line-height: 24px"
              >VIP</span
            ></template
          >
        </el-input>
      </div>
      <div
       class=""
        style="
          margin-bottom: 20px;
          text-align: center;
          margin-top: 40px;
          text-align: center;
          font-weight:bold;font-size:18px;
        "
        v-if="arr.length > 0"
      >
        <span style="color: #b7001d;">权利人：</span>
        <el-radio-group v-model="radios" @change="choose">
          <el-radio
            v-for="(item, key, index) in Data"
            :label="key"
            :key="index"
            >{{ key }}</el-radio
          >
        </el-radio-group>
      </div>
      <div style="margin-top:30px;"></div>
      <!-- <div id="printTest" style="max-width: 700px; margin: 0px auto;padding:0 40px;"> -->
      <div id="printTest" style="max-width: 700px; margin: 0px auto;padding:0 0px;">
            <div class="showTitle">
              沙井头村城市更新单元项目回迁住宅房号确认书
            </div>
            <div class="s-msg">
              <div class="t-msg">
                <div class="tag">
                  <!-- <div v-if="Qsort == 'vip348' || Qsort == 'vip349'">
                    <span>合同名称：</span>征地返回用地指标合作补偿协议
                  </div> -->
                  <div><span>合同编号：</span>{{ contract }}{{userType == 1 ? '（下称“股民回迁协议”）':''}}</div>
                </div>
                <div class="tag">
                  <div><span>被搬迁人姓名：</span>  {{ radios }}</div>
                </div>
              </div>
            </div>
            <div class="s-msg" style="width:100%;margin:0 auto;padding:30px 0!important">
              <table border="0" cellspacing="0" cellpadding="0" class="table1">
                <thead>
                  <tr>
                    <th><div :class="tableData.length>0 ? 'no-bottom':''">组团名称</div></th>
                    <th><div :class="tableData.length>0 ? 'no-bottom':''">栋号</div></th>
                    <th><div :class="tableData.length>0 ? 'no-bottom':''">楼座</div></th>
                    <th><div :class="tableData.length>0 ? 'no-bottom':''">楼层</div></th>
                    <th><div :class="tableData.length>0 ? 'no-bottom':''">房号</div></th>
                    <th><div :class="tableData.length>0 ? 'no-bottom':''">建筑面积（㎡）</div></th>
                    <th><div :class="tableData.length>0 ? 'no-bottom':''">用途</div></th>
                    <!-- <th><div :class="tableData.length>0 ? 'no-bottom':''">所在规划地块</div></th> -->
                    <th style="border-right: 1px solid;"><div :class="tableData.length>0 ? 'no-bottom':''">权利人及份额</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in tableData" :key="index">
                    <td>{{ item.block }}</td>
                    <td>{{ item.dong }}</td>
                    <td>{{ item.zuo }}</td>
                    <td>{{ item.lou }}</td>
                    <td>{{ item.fang }}</td>
                    <td>{{ item.area }}</td>
                    <td>住宅</td>
                    <!-- <td>{{item.land}}</td> -->
                    <td>
                      <div>
                        <el-input
                          class="not-print"
                          type="textarea"
                          autosize
                          placeholder=""
                          style="text-align: center"
                          v-model="item.portion"
                        >
                        </el-input>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="s-tip">
              <div style="display:flex;">
                <div>说明：</div>
              </div>
              <div style="display:flex;">
                <div>一、</div><div>本确认书载明的回迁住宅房屋建筑面积为施工图测算报告所载面积，交付的、办理产权登记的回迁住宅房屋建筑面积以竣工测绘报告所载面积为准。</div>
              </div>
              <div style="display:flex;margin-top: 10px">
                <div>二、</div><div>本确认书一式捌份，搬迁人执壹份、被搬迁人执壹份，其余由搬迁人报送政府有关部门办理相关手续，每份均具有同等法律效力。</div>
              </div>
              <div class="men1">搬迁人（盖章）：深圳市易达成投资有限公司</div>
              <div class="men1" style="margin-top: 40px">被搬迁人/授权委托人（签字按指模）：</div>
              <div class="men1" style="text-align: right;margin-top: 40px">
                日期： 2022年4月29日
              </div>
            </div>
          </div>
      <div class="page-btns " v-if="tableData.length > 0">
        <div class="sure-btn" style="margin-right: 35px" v-print="printT">
          确认打印
        </div>
        <div class="sure-btn" style="margin-right: 35px" @click="sure">
          签约完成
        </div>
        <div class="sure-btn" @click="clearData">重置</div>
      </div>
    </div>
    <div class="table-box scroll_content" v-else>
      <div class="flex-box">
        <el-button type="primary" style="margin-bottom: 20px" @click="reback"
          >返回</el-button
        >
        <el-button type="primary" style="margin-bottom: 20px" @click="daoChu">导出</el-button>
        <el-table :data="Qlist" border style="width: 100%" class="table">
          <el-table-column label="合同编号" prop="contract" align="center">
          </el-table-column>
          <!-- <el-table-column label="标段" prop="house_type" align="center">
          </el-table-column> -->
          <el-table-column label="组团名称" prop="block" align="center">
          </el-table-column>
          <el-table-column label="楼栋及楼座号" prop="unit" align="center">
          </el-table-column>
          <el-table-column label="房号" prop="room" align="center">
          </el-table-column>
          <el-table-column label="建筑面积" prop="area" align="center">
          </el-table-column>
          <el-table-column label="用途" align="center">
            <template>住宅</template>
          </el-table-column>
          <el-table-column label="所在规划地块" prop="land" align="center">
          </el-table-column>
          <el-table-column label="份额" prop="portion" align="center">
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="QData.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      showQList: false,
      printT: {
        id: "printTest",
        popTitle: "",
        extraCss: "",
        extraHead: "",
      },
      userArr: [],
      arr: [],
      input: "",
      radios: "",
      tableData: [],
      Data: [],
      contract: "",
      username: "",
      Qsort: "",
      Qlist: [],
      QData: [],
      taihao:'',
    };
  },
  methods: {
    daoChu(){
      let uin = localStorage.getItem('uin')
      window.location.href = $baseUrl+`export/export_house?useruid=${uin}`
    },
    reback() {
      this.showQList = false;
    },
    getQueList() {
      this.$http
        .post("/index/confirm_list", { page: this.currentPage })
        .then((res) => {
          if (res.data.code == 0) {
            this.Qlist = res.data.data.list;
            this.QData = res.data.data;
          }
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getQueList();
    },
    showQueList() {
      this.currentPage = 1;
      this.getQueList();
      this.showQList = true;
    },
    clearData() {
      this.arr = this.tableData = this.Data = [];
      this.input = this.radios = this.contract = "";
    },
    choose() {
      let st = "";
      this.userArr = this.radios.split("、");
      this.contract = this.Data[this.radios][0].contract;
      this.tableData = this.Data[this.radios];
      console.log(this.tableData);
    },
    getSure() {
      this.tableData = this.Data = this.arr =  [];
      this.contract = this.radios = "";
      let dt = { sort: "vip" + this.input };
      this.Qsort = "vip" + this.input;
      this.sort = this.input;
      this.$http.post("/index/comfirm_info", dt).then((res) => {
        if (res.data.code == 0) {
          this.Data = res.data.data;
          let i = 0;
          this.arr = [];
          for (let key in res.data.data) {
            i += 1;
            this.arr.push(key);
          }
          this.radios = this.arr[0];
          this.choose();
        }
      });
    },
    back() {
      this.$router.push({ path: "/" });
      localStorage.clear();
    },
    sure() {
      this.tableData[0].contract = this.contract;
      let dt = { detail: this.tableData };
      this.$http.post("/index/confirm_info_submit", dt).then((res) => {
        if (res.data.code == 0) {
          $success();
        }
      });
    },
  },
  created() {
    this.username = localStorage.getItem("name");
    this.taihao = localStorage.getItem('taihao')
  },
};
</script>
<style lang="less" scoped>
div{
  box-sizing: border-box;
}
.f-box {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
.table-box {
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}
.flex-box {
  margin: 60px 40px 0;
}
.handle {
  position: relative;
  background: #b5011d;
  height: 60px;
  line-height: 60px;
  padding: 0 30px;
  color: gold;
  font-size: 24px;
  .user {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
    span {
      margin-right: 15px;
    }
  }
}
.s-btn {
  margin: 30px auto 0;
  text-align: center;
}
.s-msg {
  padding: 30px 0 0;
  font-size: 16px;
  line-height: 22px;
  .s-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
    line-height: 24px;
    margin-bottom: 5px;
    &::after {
      content: "";
      width: 150px;
      background-image: linear-gradient(to right, #000, #fff);
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .t-msg {
    font-size: 14px;
    line-height: 40px;
    .tag {
      & > div {
        display: inline-block;
        margin-right: 68px;
      }
      span:first-child {
        margin-right: 20px;
        display: inline-block;
      }
    }
  }
}
.s-radio {
  padding: 0 30px;
  margin-top: 10px;
}
.sure-btn {
  width: 120px;
  height: 40px;
  background: #b7001d;
  font-size: 14px;
  color: gold;
  text-align: center;
  border-radius: 6px;
  line-height: 40px;
  cursor: pointer;
}
.s-tip {
  font-size: 14px;
  line-height: 24px;
  margin-top: 15px;
  .men1 {
    margin-top: 30px;
  }
}
.page-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin:70px 0;
}
/deep/.s-msg .el-input__inner {
  text-align: center;
}
/deep/.s-msg .tag .el-input__inner {
  text-align: left;
}
@media print {
  /deep/.not-print .el-input__inner {
    border: none !important;
  }
  /deep/.not-print .el-textarea__inner {
    border: none !important;
  }
  .show {
    display: block !important;
  }
}
.showTitle {
  font-size: 23px;
  font-weight: bold;
  text-align: center;
}
/deep/.el-textarea__inner {
  resize: none; //去掉右角下的斜线
  overflow-y: hidden; //解决在微信下滚动条还是显示问题
  color: #000;
  text-align: center;
  font-size: 16px;
}
.table1 {
  width: 100%;
}
table {
  border-collapse: collapse;
}
td {
  border: solid #000 1px;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
th {
  padding: 5px;
}
.no-table {
  text-align: center;
  width: 100%;
  line-height: 0;
  img {
    width: 700px;
    margin-top: 30px;
  }
}
.h-btn{
  cursor: pointer;
  display: inline-block;
  width: 80px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  color: #fff;
  font-weight: bold;
  right: 200px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  border-radius: 4px 4px 0 0;
}
/deep/.el-table thead th.is-leaf{
    color: gold;
    background-color: #b7001d!important;
}
/deep/.el-table thead.is-group th{
    color: gold;
    background-color: #b7001d!important;
}
tr{
  page-break-after:avoid;
}
tr th{
  font-weight: normal;
}
.table1 thead tr th{
  padding: 0;
  white-space: nowrap;
}
.table1 thead tr th div{
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
  padding: 5px;
  border-right: 0;
}
.table1 thead tr th div.no-bottom{
  border-bottom: 0;
}
/deep/.el-textarea__inner{
    border: none !important;
    font-family:generic-family!important;
}
.h-img{
  line-height: 0;
  height: 100%;
  overflow: hidden;
  img{
    height: 100%;
    margin-top: 4px;
  }
}
/deep/.el-pagination{
  margin: 20px 0;
}
::-webkit-scrollbar {
    display:none;
    width:0;
    height:0;
    color:transparent;
}
@page{
  margin: 80px 0;
}
</style>